import {
    createContext,
    useEffect,
    useState,
    ReactNode,
    useContext,
    useRef,
} from "react"

import { useRouter } from "next/router"
import { AuthValuesType, LoginParams, UserDataType } from "./types"
import { FirebaseContext } from "./FirebaseContext"
import { AUTH_SETTING, CART_BET_SETTING, CASINO_TRANSACTIONS_SETTING, THEME_SETTING } from "@/setting/setting"
import { loginAuth, logoutAuth, setUserAuth } from "@/core-nextv3/auth/auth.api"
import { useCore } from "@/core-nextv3/core/core"
import { Unsubscribe, doc, onSnapshot } from "firebase/firestore"
import { useSubCore } from "@/hooks/useSubCore"
import Types from "@/core-nextv3/type"
import { call } from "@/core-nextv3/util/call.api"
// import { detectIncognito } from 'detectincognitojs'
import { clearCart } from "@/core-nextv3/bet/bet.api"
import { useIdle } from "@uidotdev/usehooks"
import { useTranslation } from "next-i18next"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { getAccountBalanceAllWithTimeout, createAccount, collectAccountGameBalanceAll } from "@/core-nextv3/ximax2/ximax2.api"
import debounce from "@mui/material/utils/debounce"

// ** Defaults
const defaultProvider: AuthValuesType = {
    user         : null,
    loading      : true,
    isLogged     : false,
    setUser      : () => null,
    setLoading   : () => Boolean,
    setIsLogged  : () => Boolean,
    login        : () => Promise.resolve(),
    logout       : () => Promise.resolve(),
    CART_BACKEND : false,
}

const AuthContext = createContext(defaultProvider)

type Props = {
  children: ReactNode
}

const AuthProvider = ({ children }: Props) => 
{
    const sessionLifetime = 1000 * 60 * 10
    // const sessionLifetime                                        = 10 * 10
    const [ user, setUser ]                                      = useState<UserDataType | null>(defaultProvider.user)
    const [ loading, setLoading ]                                = useState<boolean>(defaultProvider.loading)
    const [ CART_BACKEND, setCART_BACKEND ]                      = useState<boolean>(defaultProvider.CART_BACKEND)
    const [ isLogged, setIsLogged ]                              = useState<boolean | null>(null)
    const loggedRef                                              = useRef<boolean | null>(null)
    const firebaseApp                                            = useContext(FirebaseContext)
    const { setAwaitLoadingHTTP, setBetCart }                    = useCore()
    const { setOpenLoginModal, setOpenMessageModal, setMessage } = useSubCore()
    const router                                                 = useRouter()
    const idle                                                   = useIdle(sessionLifetime)
    // const uniqueAuthRef = useRef<null>(null)
    const { t }                = useTranslation()
    const { executeRecaptcha } = useGoogleReCaptcha()

    // const redirectPath = THEME_SETTING.redirectType === 'loginPage' ? '/home/' : '/'

    // console.warn(uniqueAuthRef.current)

    const handleClose = async () => 
    {
        if (user)
        {
            const setting:any = {
                currency : "KRW",
                document : {
                    referencePath : user?.referencePath
                }
            }

            await getAccountBalanceAllWithTimeout(setting)

            user.processingCash = false;
            
            setUser({
                ...user,
                docRef        : user?.referencePath,
                referencePath : user?.referencePath,
            } as UserDataType)
           
           
        }

    }

    const handleXimax = debounce(() => 
    {
        if (document.visibilityState === "visible") 
        {
            handleClose()
        }
    }, 2000);

    useEffect(() => 
    {
 
        // const handleVisibilityChange = debounce(() => 
        // {
        //     if (document.visibilityState === "visible") 
        //     {
        //         handleClose()
        //     }
        // }, 2000);
        const handleVisibilityChange = () => 
        {

            if (user && !user?.processingCash)
            {
                
                user.processingCash = true;
                setUser({
                    ...user,
                    docRef        : user?.referencePath,
                    referencePath : user?.referencePath,
                } as UserDataType)
           
            }

            handleXimax()
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        window.addEventListener("focus", handleVisibilityChange);

        return () =>  
        {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            window.removeEventListener("focus", handleVisibilityChange );
        }
          
        
    }, [ user ]);
    
    const onLogin = (data: any) => 
    {
        // setAwaitLoadingHTTP(true)
        // console.warn("no onLogin",data,user)
        const unsubscribe: Unsubscribe = onSnapshot(
            doc(firebaseApp.firestore, data?.referencePath),
            async (docRef) => 
            {   
                // setAwaitLoadingHTTP(false)
                const _data: any = docRef.data()

                if (!_data?.status) 
                {
                    const message = t("user is not active.")
                    return handleLogoutWithMessage(message)
                } 
                else if (_data?.blocked) 
                {
                    const message = t("user is blocked.")
                    return handleLogoutWithMessage(message)
                } 
                else if (_data?.removed) 
                {
                    const message = t("user removed.")
                    return handleLogoutWithMessage(message)
                }

                if (loggedRef.current)
                {
                    const uniqueAuth = localStorage.getItem("uniqueAuth")
                    console.warn(uniqueAuth, _data?.uniqueAuth)

                    if (uniqueAuth && _data?.uniqueAuth)
                    {
                        // compara a seção do localstorage c/ realtime do BD-

                        if (String(uniqueAuth) !== String(_data?.uniqueAuth))
                        {
                            loggedRef.current = null
                            localStorage.removeItem("uniqueAuth")
                            await collectAccountGameBalanceAll({
                                currency : "KRW",
                                document : {
                                    referencePath : docRef?.ref?.path
                                }
                            })
                            return handleLogoutWithMessage(
                                `${_data?.clientIP} 에서 중복로그인되어 로그아웃됩니다.`
                            )
                        }

                        // backup
                        // if (uniqueAuthRef.current === null) {
                        //   uniqueAuthRef.current = _data?.uniqueAuth
                        // } 
                        // else if (
                        //   _data?.uniqueAuth &&
                        //   uniqueAuthRef.current != _data?. 
                        // ) {
                        //   loggedRef.current = null
                        //   return handleLogoutWithMessage(
                        //     `${_data?.clientIP} 에서 중복로그인되어 로그아웃됩니다.`
                        //   )
                        // } 
                        
                        setUser({
                            ..._data,
                            docRef        : docRef?.ref,
                            referencePath : docRef?.ref?.path,
                        } as UserDataType)
        
                    }

                    // se o token da seção local (localstorage) for nulo, desloga a seçao no back
                    else if (!uniqueAuth)
                    {
                        await handleLogout()
                    }

                }
            }
        )
    }

    // useEffect(() => 
    // {
    //     if (
    //         process.env.NODE_ENV !== "development" && 
    //         !THEME_SETTING.themeCasino &&
    //         isLogged && 
    //   idle 
    //     ) 
    //     {
    //         sessionExpired()
    //     }
    // }, [ idle ])

    // useEffect(() => 
    // {
    //     if (user?.id === "4Mz1m6HrS7WaQkqQzk34")
    //     {
    //         setCART_BACKEND(false)
    //     }
    //     else 
    //     {
    //         setCART_BACKEND(true)
    //     }
    // }, [ isLogged ])

    useEffect(() => 
    {
        setAwaitLoadingHTTP(true)

        call(Types.GET_LOGGED_AUTH_SERVER, AUTH_SETTING).then((result): void => 
        {
            // console.warn("Acionou o getLogged", result, idle)
            setAwaitLoadingHTTP(false)
            // const keepAlive2 = localStorage.getItem("keep-alive")
            // console.warn("keepAlive", keepAlive2)

            // const countdown = sessionLifetime - (new Date().getTime() - new Date(keepAlive2 ?? new Date()).getTime())
            // console.warn("countDown", countdown)

            // if (result?.status && countdown < 0) 
            // {
            //     sessionExpired()
            // }
            // else 

            if (result?.status) 
            {
                // console.warn("disparou o onLogin")
                setLoading(false)
                loggedRef.current = true
                setIsLogged(true)
                onLogin(result?.data)
            } 
            else 
            {
                setUser(null)
                setIsLogged(false)
                setLoading(false)
                loggedRef.current = null
                localStorage.removeItem("uniqueAuth")
            }
        })
    }, [])

    useEffect(() => 
    {

        if (idle)
        {
            router.reload()
        }
        
    }, [ idle ])

    const handleLogin = async (params: LoginParams) => 
    {
        if (executeRecaptcha)
        {
            try 
            {
                setAwaitLoadingHTTP(true)
                const token = await executeRecaptcha("loginUser")
        
                // const incognito = await detectIncognito()
    
                // // if (incognito.isPrivate) {
                //                   setAwaitLoadingHTTP(false)
    
                //   return {
                //     logged: false,
                //     message: 'Sign in using incognito mode is not allowed.',
                //   }
                // }
    
    
    
                const result: any = await loginAuth(
                    AUTH_SETTING.merge({
                        login    : params.login,
                        password : params.password,
                        token    : token
                    })
                )
    
                // setAwaitLoadingHTTP(false)
    
                if (result?.status) 
                {      
                    const data = result?.data

                    if (!data?.status)
                    {
                        console.warn("notApproved")
                        setUser(null)
                        setAwaitLoadingHTTP(false)
                        return { logged : false, registerNotApproved : true }
                    }
          
                    else if (data?.blocked)
                    {
                        console.warn("blocked")
                        setUser(null)
                        setAwaitLoadingHTTP(false)
                        return { logged : false, blocked : true }
                    }
    
                    else if (data?.removed)
                    {
                        console.warn("removed")
                        setUser(null)
                        setAwaitLoadingHTTP(false)
                        return { logged : false, removed : true }
                    }
    
                    else 
                    {
                        await createAccount({
                            currency : "KRW",
                            document : {
                                referencePath : result?.data?.referencePath
                            }
                        })
                    
                        setOpenLoginModal(false)
                        setIsLogged(true)
                        loggedRef.current = true
                        await collectAccountGameBalanceAll({
                            currency : "KRW",
                            document : {
                                referencePath : result?.data?.referencePath
                            }
                        })
                        onLogin(result?.data)
                        router.push("/")
                        // localStorage.setItem("keep-alive", new Date().toISOString())

                        // salva a seção unica no localstorage
                        localStorage.setItem("uniqueAuth", result?.data?.uniqueAuth)
                        return { logged : true, message : null }
                    }                    
                }
                else 
                {
                    // usuario bloqueado manda um error logo cairá aqui (gambiarra)
                    const error   = result?.error
                    const blocked = error === "Usuário bloqueado!" ? true : false
                    setAwaitLoadingHTTP(false)
                    return { 
                        logged  : false, 
                        message : result?.message || result?.error, count   : result.count,
                        blocked : blocked   
                    }
                }
            }
            catch (error)
            {
                console.error("Erro ao executar reCAPTCHA:", error)
            }
        }
        else 
        {
            console.error("recaptcha not found")
        }
    }

    const handleLogout = async () => 
    {
        setAwaitLoadingHTTP(true)
        loggedRef.current = null

        localStorage.removeItem("keep-alive")
        localStorage.removeItem("uniqueAuth")
        setUser(null)
        await clearCart(CART_BET_SETTING)
        await logoutAuth(AUTH_SETTING)
        setBetCart(null)
        setIsLogged(false)
        // loggedRef.current = false
        setLoading(false)

        // limpa o cache do login unico
        // uniqueAuthRef.current = null

        // router.reload()

        //setOpenLoginModal(router.pathname !== '/')
        router.push("/")
        setAwaitLoadingHTTP(false)
    }

    const handleLogoutWithMessage = (message: string) => 
    {
        loggedRef.current = null
        displayMessage(message)
        handleLogout()
       
    }

    const displayMessage = (message: string) => 
    {
        setMessage(message)
        setOpenMessageModal(true)
    }

    const sessionExpired = () => 
    {
        setLoading(false)
        return handleLogoutWithMessage("활동이 없어 자동 로그아웃합니다.")
    }

    const values = {
        user,
        loading,
        setUser,
        setLoading,
        setIsLogged,
        login  : handleLogin,
        logout : handleLogout,
        // keepAlive,
        CART_BACKEND,
    }

    return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
