import styled, { createGlobalStyle } from "styled-components";

const env: string | undefined    = process.env.NEXT_PUBLIC_ACCID;
const header: string | undefined = process.env.NEXT_PUBLIC_HEADER_TYPE;

const theme: any = {}

let AltStyles = createGlobalStyle``;

if (header === "alt") 
{
    AltStyles = createGlobalStyle`
 :root {
   --alt-header-menu-bottom-size: 4.3rem;
   --event-list-item-padding: 0.6rem 2rem;
 }`
}


if (env === "strike") 
{

    AltStyles = createGlobalStyle`
 :root {
  
  --body-color: #090909;
  --primary-color: #090909;
  --secondary-color: #111111;
  --slidebox: #111111;
  --yellow-color: #fec65e;
  --header: #090909;
  
  --alt-header-background: #090909;
  --alt-header-border: 2px solid #fec65e;
  --alt-header-text-color: #fff;
  --alt-header-hover-background: unset;
  --alt-header-hover-text: var(--white);
  --alt-header-size: 10rem;
  --alt-header-selected-background: var(--white);
  --alt-header-menu-bottom-size: 4.3rem;

  --bonus-btn--border: 1px solid #f90;

  --buttons-background-color: var(--body-color);

  --default-item-background: linear-gradient(180deg, #2d353c, #222);
  --default-item-border-color: linear-gradient(180deg, #2d353c, #222);
  --default-item-border-radius: 0;

  --gap-layoutPages: 1rem;

  --betItemData-background: var(--secondary-color);

  --betslip-background: var(--secondary-color);

  --event-list-gradient: linear-gradient(180deg, #ffe584 0, #a55e17 30%, #452301 50%, #7b4612 70%, #ffcd74);
  --event-list-gap: 0;
  --event-item-border-color: #242424;
  --event-list-item-padding: 0.6rem 2rem;

  --navigation-sports-game-number-color: #ff7f00;
  --navigation-sports-item-color: #191919;
  --navigation-sports-gradient: linear-gradient(137deg, #ffffea 0,#ffdb9f 5%, #c39346 20%, #ffca51 50%, #a57033 80%,#cf8b2e 95%,#e2b551);

  --banners-grid-column: 3;

  --primaryMarkets-text-color:#f90;
  
  --overlay-display: flex;

  --hightlight-background: linear-gradient(180deg, #272727, #161616);
  --hightlight-border: #424242 #181818 #232324 #2c3339;

  --primaryMarkets-text-color: #707478;
  --primaryMarkets-label-item-background: #2d353c;

  --times-gap: 0.2rem;
}
`;

}

export default AltStyles;

