import { BaseCollection } from "@/core-nextv3/model/base.collection";
import BaseModel from "@/core-nextv3/model/base.model";
import { calculatePrice } from "@/bet-nextv1/utils/SportMarketsParser";

export default class CartModel extends BaseModel 
{
    public items = new BaseCollection();
    public betBonus: any;
    rules: any;
    totalItems: number;
    live: boolean;

    constructor(params: any) 
    {
        super();
        this.rules      = params?.rules ?? null;
        this.items      = params?.items ?? new BaseCollection();
        this.totalItems = params?.totalItems ?? 1;
        this.live       = params?.live ?? false;

    }

    setTotal(value: any) 
    {
        this.total = value;
    }

    setBonus(params: any) 
    {
        const data: any = {
            betBonus : params.bonus
        }

        if (data?.betBonus && this.items.length < data?.betBonus?.quantity) 
        {
            return {
                status : false,
                error  : `Minimo de items necessários para o bonus selecionado: ${data.betBonus.quantity}`,
            }
        }

        //Valida  se todos os item do agrupamento estão acima do valor minimo
        for (const item of this.items) 
        {
            if (data?.betBonus && data?.betBonus?.minBetBonus > item.price) 
            {
                return {
                    status : false,
                    error  : "O bonus só pode ser usado se todos os jogos tiverem no minimo o valor de " + data.betBonus.minBetBonus,
                }
            }


        }

        this.betBonus = data.betBonus;
        return {
            status : true,
        }

    }

    updateBet(newBet: any)
    {    
        const foundBet = this.items.find((item) => item.bet.id == newBet.id);

        if (foundBet) 
        {
            foundBet.price = newBet.price;

            
            this.setItem(foundBet)

            return {
                status : true,
            }
        }
        else 
        {
            return {
                status : false,
            }
        }
    }

    delBonus() 
    {
        this.betBonus = null;
    }

    setItem(params: any) 
    {
        const item: any = {
            id            : params.id,
            market        : params.market,
            bet           : params.bet,
            game          : params.game,
            data          : params.data,
            participant   : params.participant,
            name          : params.name,
            price         : params.price,
            league        : params.league,
            sport         : params.sport,
            dividends     : params.dividends,
            referencePath : params.referencePath,
        }

        item.price = calculatePrice(item.price, {
            live    : this.live,
            virtual : item.game.virtual,
            sport   : item.sport,
            price   : item.price,
        }, item.dividends)

      
        
        const game     = params.game
        const inPlay   = params.game.inPlay
        const market   = params.market
        const bet      = params.bet
        const itemCart = item;


        const gameid      = params.game.id;
        let newItems: any = new BaseCollection()

        const sportMap: any = {
            Football     : "Soccer",
            Baseball     : "Beiseboll",
            Volleyball   : "Volley",
            Basketball   : "Basketball",
            "Ice Hockey" : "Hockey"
        }

        const hasTie: any      = {}
        const has1X2: any      = {}
        const hasUndeOver: any = {}
        const hasHandicap: any = {}

        if (this.items?.length > 0) 
        {
            for (const item of this.items) 
            {

                if (item.market.name == "1X2" || item.market.name == "12" || item.market.name == "12 Including Overtime") 
                {
                    if (item.bet.name == "1" || item.bet.name == "2") 
                    {
                        has1X2[item.game.id] = true
                    }
                    //se não é 1 ou 2, é empate
                    else 
                    {
                        hasTie[item.game.id] = true
                    }
                }
                else if (item.market.name == "Under/Over" || item.market.name == "Under/Over Including Overtime") 
                {
                    hasUndeOver[item.game.id] = true
                }
                else if (item.market.name == "Asian Handicap" || item.market.name == "Asian Handicap Including Overtime") 
                {
                    hasHandicap[item.game.id] = true
                }
            }
        }



        for (const item of this.items) 
        {

            newItems.push(item)
        }




        if (market.name == "1X2") 
        {
            if (!has1X2[gameid] && !hasTie[gameid]) 
            {

                if ((!hasUndeOver[gameid] || (hasUndeOver[gameid] && this.rules["winTieLossAndUnderOver" + sportMap[item.sport.name]])) && !hasHandicap[gameid]) 
                {
                    newItems.push(itemCart)
                }
                else if (hasHandicap[gameid] && !hasUndeOver[gameid]) 
                {
                    newItems = newItems.filter((item: any) => 
                    {
                        return !((item.market.name == "Asian Handicap" || item.market.name == "Asian Handicap Including Overtime") && item.game.id == gameid);
                    })
                    newItems.push(itemCart)
                }
                else if (hasUndeOver[gameid] && !hasHandicap[gameid]) 
                {
                    newItems = newItems.filter((item: any) => 
                    {
                        return !((item.market.name == "Under/Over" || item.market.name == "Under/Over Including Overtime") && item.game.id == gameid);
                    })
                    newItems.push(itemCart)
                }

            }
            else 
            {
                newItems = newItems.filter((item: any) => 
                {
                    return !(item.market.name == "1X2" && item.game.id == gameid);
                })
                newItems.push(itemCart)
            }
        }

        else if (market.name == "12" || market.name == "12 Including Overtime") 
        {
            if (!has1X2[gameid] && !hasTie[gameid]) 
            {
                if ((!hasUndeOver[gameid] || (hasUndeOver[gameid] && this.rules["winLossAndUnderOver" + sportMap[item.sport.name]])) && !hasHandicap[gameid]) 
                {
                    newItems.push(itemCart)
                }
                else if (hasHandicap[gameid] && !hasUndeOver[gameid]) 
                {
                    newItems = newItems.filter((item: any) => 
                    {
                        return !((item.market.name == "Asian Handicap" || item.market.name == "Asian Handicap Including Overtime") && item.game.id == gameid);
                    })
                    newItems.push(itemCart)
                }
            }
            else 
            {
                newItems = newItems.filter((item: any) => 
                {
                    return !((item.market.name == "12" || item.market.name == "12 Including Overtime") && item.game.id == gameid);
                })
                newItems.push(itemCart)
            }
        }

        else if (market.name == "Asian Handicap" || market.name == "Asian Handicap Including Overtime") 
        {

            if ((!hasUndeOver[gameid] || ((hasUndeOver[gameid] && this.rules["handcapAndUnderOver" + sportMap[item.sport.name]]))) && !hasHandicap[gameid] && !has1X2[gameid]) 
            {
                newItems.push(itemCart)
            }
            else if (hasHandicap[gameid]) 
            {
                newItems = newItems.filter((item: any) => 
                {
                    return !((item.market.name == "Asian Handicap" || item.market.name == "Asian Handicap Including Overtime") && item.game.id == gameid);
                })
                newItems.push(itemCart)
            }
            else if (has1X2[gameid] && !hasUndeOver[gameid]) 
            {
                newItems = newItems.filter((item: any) => 
                {
                    return !((item.market.name == "1X2" || item.market.name == "12" || item.market.name == "12 Including Overtime") && item.game.id == gameid);
                })
                newItems.push(itemCart)
            }
            else if (hasUndeOver[gameid] && !has1X2[gameid]) 
            {
                newItems = newItems.filter((item: any) => 
                {
                    return !((item.market.name == "Under/Over" || item.market.name == "Under/Over Including Overtime") && item.game.id == gameid);
                })
                newItems.push(itemCart)
            }
        }
        else if (market.name == "Under/Over" || market.name == "Under/Over Including Overtime") 
        {
            if (!hasUndeOver[gameid]) 
            {
                if ((hasHandicap[gameid] && this.rules["handcapAndUnderOver" + sportMap[item.sport.name]]) ||
          (!hasTie[gameid] && has1X2[gameid] && (this.rules["winLossAndUnderOver" + sportMap[item.sport.name]] || this.rules["winTieLossAndUnderOver" + sportMap[item.sport.name]])) ||
          (!has1X2[gameid] && hasTie[gameid] && (this.rules["winLossAndUnderOver" + sportMap[item.sport.name]] || this.rules["winTieLossAndUnderOver" + sportMap[item.sport.name]]))) 
                {
                    newItems.push(itemCart)
                }
                else if (!hasHandicap[gameid] && !has1X2[gameid] && !hasTie[gameid]) 
                {
                    newItems.push(itemCart)
                }
                else if ((hasTie[gameid] || has1X2[gameid]) && (!this.rules["winLossAndUnderOver" + sportMap[item.sport.name]] && !this.rules["winTieLossAndUnderOver" + sportMap[item.sport.name]])) 
                {
                    newItems = newItems.filter((item: any) => 
                    {
                        return !((item.market.name == "1X2" || item.market.name == "12" || item.market.name == "12 Including Overtime") && item.game.id == gameid);
                    })
                    newItems.push(itemCart)
                }
                else if (hasHandicap[gameid] && !this.rules["handcapAndUnderOver" + sportMap[item.sport.name]]) 
                {
                    newItems = newItems.filter((item: any) => 
                    {
                        return !((item.market.name == "Asian Handicap" || item.market.name == "Asian Handicap Including Overtime") && item.game.id == gameid);
                    })
                    newItems.push(itemCart)
                }
            }
            else 
            {
                newItems = newItems.filter((item: any) => 
                {
                    return !((item.market.name == "Under/Over" || item.market.name == "Under/Over Including Overtime") && item.game.id == gameid);
                })
                newItems.push(itemCart)
            }
        }
        else 
        {
            console.log("sem regra neste market - ", market.name)
            newItems.push(itemCart)
        }

        this.items = newItems;

        const games: any = {}
        let total        = 1

        for (const item of this.items) 
        {
            if (!games[item.game.id]) 
            {
                games[item.game.id] = { total : 0 }
            }

            //
            if (Number.isFinite(item.price))
            {
                games[item.game.id].total += item.price
            }
            else 
            {
                games[item.game.id].total += parseFloat(item.price)
            }

            
        }

        const keys = Object.keys(games)

        for (const key of keys) 
        {
            total *= parseFloat(games[key].total)
            
        }

        
        this.totalItems = Math.floor(total * 100) / 100
    }

    setMinigameItem(params: any) 
    {
        const miniItem: any = {
            document     : params.document,
            data         : params.data,
            providerName : params.providerName,
            game         : params.game,
            minigame     : params.minigame,
            bet          : params.bet,
            price        : params.price,
            gameSettings : params.gameSetting,
        }

        this.items.push(miniItem);
    }



    delItem(item: any) 
    {
        this.items.del(item);

        const games: any = {}
        let total        = 1

        for (const item of this.items) 
        {
            if (!games[item.game.id]) 
            {
                games[item.game.id] = { total : 0 }
            }

            //
            games[item.game.id].total += item.price
        }

        const keys = Object.keys(games)

        for (const key of keys) 
        {
            total *= games[key].total
        }

        this.totalItems = Math.floor(total * 100) / 100
    }

    clear() 
    {
        this.items.delItems(this.items);
    }
}
