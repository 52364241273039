//import '@/styles/globals.scss'
import type { AppProps } from "next/app"
import "@splidejs/react-splide/css"
//import 'primereact/resources/themes/lara-light-indigo/theme.css'
import { appWithTranslation } from "next-i18next"
import { Header } from "../Header"
import { Toaster } from "react-hot-toast"
import { useEffect, useState } from "react"
import { FooterMobile } from "../FooterMobile"
import { useResponsive } from "@/core-nextv3/util/useResponsive"
import { NavigationSports } from "../NavigationSports"
import { BetSlip } from "../BetSlipe"
import { FirebaseProvider } from "../context/FirebaseContext"
import { AuthProvider } from "../context/AuthContext"
import { Loading } from "../Loading"
import { MessageModal } from "../MessageModal"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { MaintanceModal } from "../MaintanceModal"
import { useCore } from "@/core-nextv3/core/core"
import { call } from "@/core-nextv3/util/call.api"
import { useSubCore } from "@/hooks/useSubCore"
import Types from "@/core-nextv3/type"
import { ACCOUNT_SETTING, THEME_SETTING } from "@/setting/setting"
import { useRouter } from "next/router"
import { HeaderTwo } from "../HeaderTwo"
import GlobalClasses from "@/bet-nextv1/styles/globalClasses"
import AltStyles from "@/bet-nextv1/styles/alt"

const AppPage = ({ Component, pageProps }: any) => 
{
    const { isDesktop }                                                  = useResponsive()
    const [ maintance, setMaintance ]                                    = useState(false)
    const { setSelectedLanguage, selectedLanguage, setAwaitLoadingHTTP } = useCore()
    const { setSelectedTimezone, setDividends }                          = useSubCore()
    const router                                                         = useRouter()

    const fetchTimezone = async () => 
    {
        let timezone: any

        if (selectedLanguage === "kr") 
        {
            timezone = { id : "Asia/Seoul", label : "Asia/Seoul", value : "+09:00" }
        }
        else if (selectedLanguage === "pt") 
        {
            timezone = {
                id    : "America/Sao_Paulo",
                label : "America/Sao_Paulo",
                value : "-03:00",
            }
        }
        else 
        {
            timezone = { id : "Asia/Seoul", label : "Asia/Seoul", value : "+09:00" }
        }

        await call(
            Types.SET_TIMEZONE_UTIL_SERVER,
            ACCOUNT_SETTING.merge({
                timezone : timezone,
            })
        )

        setSelectedTimezone(timezone?.label)
    }

    useEffect(() => 
    {
        fetchTimezone()
        setSelectedLanguage(pageProps.locale ?? "kr")
    }, [])


    // if (router.asPath.includes("debug")) 
    // {
    //     return <Component {...pageProps} />
    // }

    const getHeader = () => 
    {
        if (THEME_SETTING.headerType == "alt") 
        {
            return <HeaderTwo {...pageProps} />
        }
        else 
        {
            return <Header {...pageProps} />
        }
    }

    
    const getLayout = () => 
    {
        if (router?.asPath === "/" && THEME_SETTING.redirectType === "loginPage") 
        {
            return (
                <>
                    <div className='indexPage'>
                        <Component {...pageProps} />
                    </div>
                </>
            )
        }
        else 
        {
            return (
                <>
                    {getHeader()}
                    <div className='layoutPages'>
                        <div className='layoutRight'>
                            <NavigationSports />
                        </div>
                        <div className='middle'>
                            <Component {...pageProps} />
                        </div>

                        <div className='layoutLeft'>
                            <BetSlip
                                {...pageProps}
                                homeBannersLiks={pageProps.sizeBanners}
                            />
                        </div>
                    </div>
                </>
            )
        }
    }

    return (
        <>
            <GoogleReCaptchaProvider
                reCaptchaKey={"" + process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                scriptProps={{
                    async    : false,
                    defer    : false,
                    appendTo : "head",
                    nonce    : undefined,
                }}
            >
                <FirebaseProvider>
                    <AuthProvider>
                        <GlobalClasses />
                        <AltStyles />
                        {/* {getHeader()} */}
                        <div className='contentWrapper'>
                            {getLayout()}
                        </div>

                        {!isDesktop && <FooterMobile {...pageProps} />}

                        <Toaster
                            position='top-center'
                            reverseOrder={true}
                            toastOptions={{
                                duration : 3000,
                            }}
                            containerStyle={{
                                zIndex : 999999,
                            }}
                        />

                        <Loading />

                        <MessageModal account={pageProps.account} />

                        {maintance && <MaintanceModal />}
                    </AuthProvider>
                </FirebaseProvider>
            </GoogleReCaptchaProvider>
        </>
    )
}

export default appWithTranslation(AppPage)
