import Types from "../type";

import { ISetting, setting } from "../util/setting";
import { call } from "../util/call.api";
import { useRouter } from "next/router";
import { useEffect } from "react";

const useGetDocument = (setting:ISetting, onSuccess?:any) => 
{
	const router = useRouter();	
	
	useEffect(() => 
	{
		call(Types.GET_DOCUMENT_SERVER, setting).then((result) => 
		{
			if(onSuccess)
			{
				onSuccess(result.data)
			}			
		});	
	}, [router.asPath]);
}

const collectionDocument = async (setting: ISetting) => {
	const result = await call(Types.COLLECTION_DOCUMENT_SERVER, setting);

	return result;
}

const countDocument = async (setting: ISetting) => {
	const result = await call(Types.COUNT_DOCUMENT_SERVER, setting);

	return result;
}

const countOwnerDocument = async (setting: ISetting) => {
	const result = await call(Types.COUNT_OWNER_DOCUMENT_SERVER, setting);

	return result;
}

const collectionOwnerDocument = async (setting: ISetting) => {
	const result = await call(Types.COLLECTION_OWNER_DOCUMENT_SERVER, setting);

	return result;
}

const getDocument = async (setting: ISetting) => {
	const result = await call(Types.GET_DOCUMENT_SERVER, setting);

	return result;
}

const addDocument = async (setting: ISetting) => 
{
	const result = await call(Types.ADD_DOCUMENT_SERVER, setting);

	return result;
}

const setDocument = async (setting: ISetting) => {
	const result = await call(Types.SET_DOCUMENT_SERVER, setting);

	return result;
}

const incrementDocument = async (setting: ISetting) => {
	const result = await call(Types.INCREMENT_DOCUMENT_SERVER, setting);

	return result;
}

const cancelDocument = async (setting: ISetting) => {
	const result = await call(Types.DELETE_DOCUMENT_SERVER, setting);

	return result;
}
const setViews = async (setting: ISetting) => {
	const result = await call(Types.SET_VIEWS_SERVER, setting);

	return result;
}

const flatDocument = async (setting: ISetting) => {
	const result = await call(Types.FLAT_DOCUMENT_SERVER, setting);

	return result;
}

const clearCache = async (setting: ISetting) => {
	const result = await call(Types.CLEAR_CACHE_SERVER, setting);

	return result;
}

export {
	collectionDocument,
	getDocument,
	addDocument,
	setViews,
	setDocument,
	cancelDocument,
	incrementDocument,
	collectionOwnerDocument,
	countDocument,
	useGetDocument,
	flatDocument,
	clearCache,
	countOwnerDocument,
}