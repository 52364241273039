import { create } from "zustand";

type UseSubCoreProps = {
    games: any
    sports: any
    locations: any
    selectedLocation: any
    selectedLeague: any
    selectedGame: any
    leagueGames: any
    openLoginModal: any
    openMessageModal: any
    message: any
    messageModalIcon: any
    selectedBet: any
    selectedIndex: any
    typeLogin: any
    cancelLimit: any
    setGames: any
    openAdditionalOptions: any
    selectedMarket: any
    selectedSport: any
    virtual: any
    startDate: any
    endDate: any
    selectedTimezone: any
    selectedMgm: any
    openModalTimeLive: any
    dividends: any
    cartChanges: any
    setSports: any
    setLocations: any
    setLeagueGames: any
    setSelectedLeague: any
    setSelectedGame: any
    setSelectedLocation: any
    setOpenLoginModal: any
    setOpenMessageModal: any
    setMessage: any
    setMessageModalIcon: any
    setSelectedBet: any
    setSelectedIndex: any
    setOpenAdditionalOptions: any
    setSelectedMarket: any
    setTypeLogin: any
    setCancelLimit: any
    setSelectedSport: any
    setTotalGames: any
    totalGames: any
    reloadGames: any
    setReloadGames: any
    reloadLiveGames: any
    setReloadLiveGames: any
    setVirtual: any
    setStartDate: any
    setEndDate: any
    setSelectedTimezone: any
    setSelectedMgm: any
    setOpenModalBetslip: any
    openModalBetslip: any
    setOpenModalTimeLive: any
    setDividends: any
    messageNumber: any
    setMessageNumber: any
    cart: any
    setCart: any
    gameConfigs: any
    setGameConfigs: any 
    popups: any
    setPopups: any
    setCartChanges: any
    filterPerLeague: any
    setFilterPerLeague: any
    selectedId: any
    setSelectedId: any
    homeBannersLiks: any
    setHomeBannersLinks: any
};

export const useSubCore = create<UseSubCoreProps>((set: any, get: any) =>
    ({
        games                    : [],
        sports                   : [],
        locations                : [],
        selectedLeague           : null,
        leagueGames              : [],
        selectedGame             : null,
        selectedLocation         : null,
        openLoginModal           : false,
        openMessageModal         : false,
        message                  : "",
        messageModalIcon         : "success",
        selectedBet              : null,
        selectedIndex            : null,
        openAdditionalOptions    : false,
        selectedMarket           : null,
        typeLogin                : "login",
        cancelLimit              : 3,
        totalGames               : 0,
        selectedSport            : null,
        reloadGames              : true,
        reloadLiveGames          : true,
        virtual                  : null,
        startDate                : null,
        endDate                  : null,
        selectedTimezone         : null,
        selectedMgm              : "baccarat",
        openModalBetslip         : false,
        openModalTimeLive        : false,
        dividends                : {},
        messageNumber            : null,
        cart                     : null,
        gameConfigs              : null,
        popups                   : [],
        cartChanges              : false,
        filterPerLeague          : null,
        selectedId               : null,
        homeBannersLiks          : [],
        setTotalGames            : (value: any) => set((state: any) => ({ totalGames : value })),
        setGames                 : (value: any) => set((state: any) => ({ games : value })),
        setSports                : (value: any) => set((state: any) => ({ sports : value })),
        setLocations             : (value: any) => set((state: any) => ({ locations : value })),
        setSelectedLeague        : (value: any) => set((state: any) => ({ selectedLeague : value })),
        setLeagueGames           : (value: any) => set((state: any) => ({ leagueGames : value })),
        setSelectedGame          : (value: any) => set((state: any) => ({ selectedGame : value })),
        setSelectedLocation      : (value: any) => set((state: any) => ({ selectedLocation : value })),
        setOpenLoginModal        : (value: any) => set((state: any) => ({ openLoginModal : value })),
        setOpenMessageModal      : (value: any) => set((state: any) => ({ openMessageModal : value })),
        setMessage               : (value: any) => set((state: any) => ({ message : value })),
        setMessageModalIcon      : (value: any) => set((state: any) => ({ messageModalIcon : value })),
        setSelectedBet           : (value: any) => set((state: any) => ({ selectedBet : value })),
        setSelectedIndex         : (value: any) => set((state: any) => ({ selectedIndex : value })),
        setOpenAdditionalOptions : (value: any) => set((state: any) => ({ openAdditionalOptions : value })),
        setSelectedMarket        : (value: any) => set((state: any) => ({ selectedMarket : value })),
        setTypeLogin             : (value: any) => set((state: any) => ({ typeLogin : value })),
        setCancelLimit           : (value: any) => set((state: any) => ({ cancelLimit : value })),
        setSelectedSport         : (value: any) => set((state: any) => ({ selectedSport : value })),
        setReloadGames           : (value: any) => set((state: any) => ({ reloadGames : value })),
        setReloadLiveGames       : (value: any) => set((state: any) => ({ reloadLiveGames : value })),
        setVirtual               : (value: any) => set((state: any) => ({ virtual : value })),
        setStartDate             : (value: any) => set((state: any) => ({ startDate : value })),
        setEndDate               : (value: any) => set((state: any) => ({ endDate : value })),
        setSelectedTimezone      : (value: any) => set((state: any) => ({ selectedTimezone : value })),
        setSelectedMgm           : (value: any) => set((state: any) => ({ selectedMgm : value })),
        setOpenModalBetslip      : (value: any) => set((state: any) => ({ openModalBetslip : value })),
        setOpenModalTimeLive     : (value: any) => set((state: any) => ({ openModalTimeLive : value })),
        setDividends             : (value: any) => set((state: any) => ({ dividends : value })),
        setMessageNumber         : (value: any) => set((state: any) => ({ messageNumber : value })),
        setCart                  : (value: any) => set((state: any) => ({ cart : value })),
        setGameConfigs           : (value: any) => set((state: any) => ({ gameConfigs : value })),
        setPopups                : (value: any) => set((state: any) => ({ popups : value })),
        setCartChanges           : (value: any) => set((state: any) => ({ cartChanges : value })),
        setFilterPerLeague       : (value: any) => set((state: any) => ({ filterPerLeague : value })),
        setSelectedId            : (value: any) => set((state: any) => ({ selectedId : value })),
        setHomeBannersLinks      : (value: any) => set((state: any) => ({ homeBannersLiks : value })),
    }));