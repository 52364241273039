import styles from "./styles.module.scss"
import Link from "next/link"
import { useState } from "react"
import { BiUserCircle } from "react-icons/bi"
import { BsFillTicketFill } from "react-icons/bs"
import { FaTableTennisPaddleBall } from "react-icons/fa6"
import { BetSlip } from "../BetSlipe"
import { NavigationSports } from "../NavigationSports"
import { useCore } from "@/core-nextv3/core/core"
import { AccountModal } from "../AccountModal"
import { useTranslation } from "next-i18next"
import type { AppProps } from "next/app"
import { useSubCore } from "@/hooks/useSubCore"

export const FooterMobile = ({ sportLevels, liveLevels, mgmLevels }) => 
{
    // const [openModalBetslip, setOpenModalBetslip] = useState(false)
    const [ openModalPopularEvents, setOpenModalPopularEvents ] = useState(false)
    const [ openModalAccount, setOpenModalAccount ]             = useState(false)
    const { betCart }                                           = useCore()
    const { t }                                                 = useTranslation()

    const { openModalBetslip, setOpenModalBetslip } = useSubCore()

    const handleOpenMenu = (func?: any) => 
    {
        setOpenModalBetslip(false)
        setOpenModalPopularEvents(false)

        if (func) 
        {
            func(true)
        }
    }

    return (
        <>
            <div className={styles.footerMobile}>
                <ul className={styles.footerMenu + " " + styles.dLgNone}>
                    <li>
                        {/* <Link href="/" className={styles.dGrid + ' ' + styles.justifyContentCenter}>
                            <span>
                                <FaTableTennisPaddleBall />
                            </span>
                            <span className={styles.texta}>Sports</span>
                        </Link> */}
                        <div
                            onClick={() => handleOpenMenu(setOpenModalPopularEvents)}
                            className={styles.dGrid + " " + styles.justifyContentCenter}
                        >
                            <span>
                                <FaTableTennisPaddleBall />
                            </span>
                            <span className={styles.texta}>{t("Esportes")}</span>
                        </div>
                    </li>

                    <li>
                        {/* <div
                            onClick={() => setOpenModalPopularEvents(true)}
                            className={styles.dGrid + ' ' + styles.justifyContentCenter}
                        >
                            <span>
                                <AiFillGift />
                            </span>
                            <span className={styles.texta}>Events</span>
                        </div> */}
                        <Link
                            href='/casino'
                            onClick={() => handleOpenMenu()}
                            className={styles.dGrid + " " + styles.justifyContentCenter}
                        >
                            <span>
                                <img src='/assets/img/casino_yellow.png'></img>
                            </span>
                            <span className={styles.texta}>{t("Cassino")}</span>
                        </Link>
                    </li>

                    <li className={styles.headerBartwo + " " + styles.dLgNone}>
                        <span className={styles.bars}>
                            <i className='fas fa-bars'></i>
                        </span>
                        {betCart?.items && (
                            <span className={styles.numItems}>{betCart.items.length}</span>
                        )}

                        {/* <span className={styles.cros}>
                            <RxHamburgerMenu />
                        </span> */}

                        <div
                            onClick={() => handleOpenMenu(setOpenModalBetslip)}
                            className={styles.dGrid + " " + styles.justifyContentCenter}
                        >
                            <span>
                                <BsFillTicketFill />
                            </span>
                        </div>
                    </li>

                    <li>
                        <Link
                            href='/mini/mgm/baccarat/1'
                            className={styles.dGrid + " " + styles.justifyContentCenter}
                        >
                            <span>
                                <img src='/assets/img/mini-game_yellow.png'></img>
                            </span>
                            <span className={styles.texta}>{t("Mini Game")}</span>
                        </Link>
                    </li>

                    <li>
                        {/* <Link href="/dashboard" className={styles.dGrid + ' ' + styles.justifyContentCenter}>
                            <span>
                                <BiUserCircle />
                            </span>
                            <span className={styles.texta}> Account</span>
                        </Link> */}
                        <div
                            onClick={() => handleOpenMenu(setOpenModalAccount)}
                            className={styles.dGrid + " " + styles.justifyContentCenter}
                        >
                            <span>
                                <BiUserCircle />
                            </span>
                            <span className={styles.texta}>{t("Conta")}</span>
                        </div>
                    </li>
                </ul>
            </div>

            {openModalPopularEvents && (
                <NavigationSports
                    closePopularEvents={setOpenModalPopularEvents}
                    openModalPopularEvents={openModalPopularEvents}
                />
            )}

            {openModalBetslip && (
                <BetSlip
                    mgmLevels={mgmLevels}
                    liveLevels={liveLevels}
                    sportLevels={sportLevels}
                    close={setOpenModalBetslip}
                    openModalBetslip={openModalBetslip}
                />
            )}

            {openModalAccount && (
                <AccountModal
                    close={setOpenModalAccount}
                    openModalACcount={openModalAccount}
                />
            )}
        </>
    )
}
