import Types from "../type";
import { call } from "../util/call.api";

const collectAccountGameBalanceAll = async (setting:any) => 
{
    const result = await call(Types.XIMAX2_COLLECT_ACCOUNT_BALANCE_ALL_SERVER, setting)
    return result
}

const getAccountBalanceAllWithTimeout = async (setting:any) => 
{
    const result = await call(Types.XIMAX2_GET_ACCOUNT_BALANCE_ALL_WITH_TIMEOUT_SERVER, setting)
    return result
}

const createAccount = async (setting:any) => 
{
    const result = await call(Types.XIMAX2_CREATE_ACCOUNT_SERVER, setting)
    return result
}

const getGameUrl = async (setting:any) => 
{
    const result = await call(Types.XIMAX2_GET_GAME_URL_SERVER, setting)
    return result
}

const addMemberPoint = async (setting:any) => 
{
    const result = await call(Types.XIMAX2_ADD_MEMBER_POINT_SERVER, setting)
    return result
}

const substractMemberPoint = async (setting:any) => 
{
    const result = await call(Types.XIMAX2_SUBSTRACT_MEMBER_POINT_SERVER, setting)
    return result
}

export {
    collectAccountGameBalanceAll,
    getAccountBalanceAllWithTimeout,
    createAccount,
    getGameUrl,
    addMemberPoint,
    substractMemberPoint,
}