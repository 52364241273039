import "@/bet-nextv1/styles/globals.scss"
import AppPage from "@/bet-nextv1/AppPage";

function MyApp({ Component, pageProps }: any) 
{
    return (
        <AppPage Component={Component} pageProps={pageProps} />
    )
}

export default MyApp;
